<template>
  <div>
    <heade currentrStr="enroll"></heade>
    <div class="enroll common-width">
      <a-back-top />

      <a-tabs
        default-active-key="1"
        style="margin-top: 30px; text-align: left"
        @change="callback"
      >
        <a-tab-pane key="本期" tab="本期">
          <a-card
            :title="item.project"
            class='width-card'
            v-for="(item, index) in list"
            :key="index"
          >
            <a
              slot="extra"
              href="javascript:;"
              @click="to('./enrollDetail', item)"
              >详情</a
            >
            <p>参赛时间：{{ item.testperiod }}</p>
            <p>参赛学校：{{ item.school }}</p>
            <p>队伍名称：{{ item.title }}</p>
            <a-button size="small" style="float: right" disabled>{{
              item.status
            }}</a-button>
          </a-card>
          <div v-if="list.length == 0">{{ msgAdd }}</div>
        </a-tab-pane>

        <a-tab-pane key="往期" tab="往期">
          <a-card
            :title="item.project"
            class='width-card'
            v-for="(item, index) in list"
            :key="index"
          >
            <a
              slot="extra"
              href="javascript:;"
              @click="to('./enrollDetail', item)"
              >详情</a
            >
            <p>参赛时间：{{ item.testperiod }}</p>
            <p>参赛学校：{{ item.school }}</p>
            <p>队伍名称：{{ item.title }}</p>
            <a-button size="small" style="float: right" disabled >{{
              item.status
            }}</a-button>
          </a-card>
          <div v-if="list.length == 0">{{ msgAdd }}</div>
        </a-tab-pane>
        <!-- <a-tab-pane key="3" tab="待开始">
  
      <a-card title="报名参赛的队伍名称报名参赛的队伍名称报名参赛的队伍名称" style="width: 300px">
        <a slot="extra" href="javascript:;">详情</a>
        <p>字段描述</p>
        <p>字段描述</p>
        <p>字段描述</p>
            <a-button size='small' style='float:right' disabled>待开始</a-button>
      </a-card>

      </a-tab-pane>
        <a-tab-pane key="4" tab="已结束">
   
       <a-card title="报名参赛的队伍名称报名参赛的队伍名称报名参赛的队伍名称" style="width: 300px">
        <a slot="extra" href="javascript:;">详情</a>
        <p>字段描述</p>
        <p>字段描述</p>
        <p>字段描述</p>
          <a-button size='small' style='float:right' disabled>已结束</a-button>
      </a-card>
    
        <a-card title="报名参赛的队伍名称报名参赛的队伍名称报名参赛的队伍名称" style="width: 300px">
        <a slot="extra" href="javascript:;">详情</a>
        <p>字段描述</p>
        <p>字段描述</p>
        <p>字段描述</p>
                  <a-button size='small' style='float:right' disabled>已结束</a-button>
      </a-card>
        <a-card title="报名参赛的队伍名称报名参赛的队伍名称报名参赛的队伍名称" style="width: 300px">
        <a slot="extra" href="javascript:;">详情</a>
        <p>字段描述</p>
        <p>字段描述</p>
        <p>字段描述</p>
                  <a-button size='small' style='float:right' disabled>已结束</a-button>
      </a-card>

      </a-tab-pane> -->
      </a-tabs>
    </div>
  </div>
</template>
<script>
import heade from "@/components/header.vue";
export default {
  data() {
    return {
      list: [],
      msgAdd: "",
    };
  },
  components: {
    heade,
  },
  mounted() {
    this.init();
  },
  methods: {
    callback(key) {
      this.init(key);
    },
    to(url, info) {
       window.localStorage.setItem("anjing-info", JSON.stringify(info));
       window.localStorage.setItem("anjing-parent-type-_id",info._id)
       window.localStorage.setItem("anjing-parent-type-editsort",info.editsort)
       window.localStorage.setItem("anjing-urltype", info.urltype);
       window.localStorage.setItem('anjing-teamtype',info.teamtype)
       console.log(info._id)
       
      this.$router.push({ path: url });
    },

    init(period = "本期") {
      this.list = [];
      this.msgAdd = "";
      let data = {
        op: "myteams",
        period: period,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/signup.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.list = response.data.data;
           
            _this.msgAdd = "暂无数据";
            
          } else if (response.data.value == "sessionerror") {
            // 未登录
            _this.$message.info(response.data.msg);
          
               setTimeout(() => {
            _this.$router.push({ path: "./login-reg-pass" ,query:{'url':'./index'}});
          }, 800);
            _this.msgAdd = response.data.msg;
          } else {
            _this.$message.info(response.data.msg);
            _this.msgAdd = response.data.msg;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
body {
  /* background:#ccc; */
}
.enroll {
  /* width:1240px;
    margin:0 auto; */
  /* display: flex;
    justify-content: space-between; */
  /* background: #fcfcfc; */
  padding: 20px;

}
/deep/ .ant-card-body {
  text-align: left;
}
/deep/ .ant-card-bordered {
  float: left;

  margin-right: 10px;
  margin-top: 50px;
}
/deep/ .ant-tabs .ant-tabs-left-bar {
  padding-top: 50px;
}
/deep/ .ant-tabs .ant-tabs-left-content {
  /* height:calc(100vh - 50px); */
  overflow: auto;
}
.width-card{
   width: 47% !important;

}
@media (max-width: 1100px) {
.width-card{
    width:100% !important;
  
  }
}
</style>
